import React from "react";

import { HeroContainer } from "./styles";
import SideComponent from "../SideComponent";
import ImageComponent from "../../images/promotional/flyer-sweet-wheels.png";
import ImageComponentTwo from "../../images/promotional/bicycle-3.png";

const HeroSection = () => {
  // const onHover = () => {
  //   setHover(!hover);
  // };

  return (
    <HeroContainer id="home">
      <SideComponent>
        <img src={ImageComponent} alt="Ice Cream" />
      </SideComponent>
      <SideComponent>
        {/* <img src={ImageComponentTwo} alt="Ice Cream" /> */}
        <span
          style={{
            fontFamily: "Rubik Dirt, system-ui",
            fontStyle: "normal",
            fontSize: "100px",
            textAlign: "left",
            lineHeight: 0.8,
            padding: 20,
          }}
        >
          We are now open.
        </span>
        <br />
        <br />

        <h1
          style={{
            fontFamily: "McLaren, sans-serif",
            fontWeight: 400,
            fontStyle: "normal",
            fontSize: "60px",
            textAlign: "left",
            lineHeight: 1,
            padding: 20,
          }}
        >
          What's your favorite ice cream flavor?
        </h1>
      </SideComponent>
      <SideComponent>
        <img src={ImageComponentTwo} alt="Ice Cream" />
        {/* <div style={{ width: "100%", height: "100%" }}>
          <video width="100%" height="100%" autoPlay loop controls>
            <source
              src="https://res.cloudinary.com/dfiibjobg/video/upload/v1712260148/Gallery/icecreamtime_pdj2rg.mp4?autoplay=true"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div> */}
      </SideComponent>
    </HeroContainer>
  );
};

export default HeroSection;
