import React from "react";
import { useSpring, animated } from "react-spring";

import pic1 from "../../images/_icecream/ice-cream-center-2.png";
import pic2 from "./str-left-bottom.png";
import pic3 from "./str-left-top.png";
import pic4 from "./str-right-bottom.png";
import pic5 from "./str-right-top.png";

import "./styles.css";

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];

const transform =
  (ratio, offsetX = 0, offsetY = 0) =>
  (x, y) =>
    `translate3d(${x / ratio + offsetX}px, ${y / ratio + offsetY}px, 0)`;

function Card() {
  const [{ xy }, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  const handleMouseMove = ({ clientX: x, clientY: y }) =>
    set({ xy: calc(x, y) });

  return (
    <div className="container" onMouseMove={handleMouseMove}>
      <animated.div
        className="card1"
        style={{
          transform: xy.to(transform(10)),
          backgroundImage: `url(${pic1})`,
        }}
      />
      <animated.div
        className="card2"
        style={{
          transform: xy.to(transform(8, 35, -230)),
          backgroundImage: `url(${pic2})`,
        }}
      />
      <animated.div
        className="card3"
        style={{
          transform: xy.to(transform(6, -250, -200)),
          backgroundImage: `url(${pic3})`,
        }}
      />
      <animated.div
        className="card4"
        style={{
          transform: xy.to(transform(3.5)),
          backgroundImage: `url(${pic4})`,
        }}
      />

      <animated.div
        className="card5"
        style={{
          transform: xy.to(transform(3.5)),
          backgroundImage: `url(${pic5})`,
        }}
      />
    </div>
  );
}

export default Card;
