import React from "react";
import { ApproachContainer, ServicesH1, ServicesP } from "./styles";
import SideComponent from "../StandarContainer";
import oldPaperImage from "./img/old_paper.png";

const Menu = () => {
  return (
    <ApproachContainer id="services">
      <SideComponent
        style={{
          backgroundSize: "150px 150px",
          backgroundImage: `url(${oldPaperImage})`,
          backgroundRepeat: "repeat",
        }}
      >
        <ServicesH1
          style={{
            fontFamily: "Rubik Dirt, system-ui",
            fontSize: "1.6rem",
            textAlign: "center",
            position: "absolute",
            marginBottom: "190px",
          }}
        >
          OUR APPROACH
        </ServicesH1>
        <ServicesP>
          Our ice cream is made with the finest ingredients, carefully selected
          to ensure exceptional quality. We want to sell ice cream to bring joy
          and provide a fun experience to all our customers. Every scoop is a
          burst of flavor, made with love and dedication just for you. We
          believe that ice cream should be a source of happiness, and that's why
          we strive to create the most delicious and innovative flavors.
        </ServicesP>
      </SideComponent>
    </ApproachContainer>
  );
};

export default Menu;
