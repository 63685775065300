import React from "react";
import styled, { keyframes } from "styled-components";

import { Link } from "react-router-dom";

import backgroundImage from "../../images/_background/stripes-5.png";
import drippingImage from "../../images/strawberry-dripping.png";

export const FooterContainer = styled.footer`
  position: relative;
  z-index: 1;
  background-image: url(${backgroundImage});
  background-size: auto 400px;
  background-position: center top;
  background-repeat: repeat;
  background-color: rgb(255 137 137);
  background-blend-mode: overlay;
`;

export const moveRightTest = keyframes`
  from {
    transform: translateX(-110vw);
  }

  to {
    transform: translateX(110vw);

  }
`;

export const StyledAnimationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 7%;
  position: relative;
  left: 0%;
  /* bottom: 70px; */
  z-index: 10;

  animation: ${moveRightTest} 20s linear infinite;

  @media (min-width: 1800px) and (max-width: 1920px) {
    bottom: 50px;
  }

  @media (min-width: 1501px) and (max-width: 1800px) {
    bottom: 47px;
  }

  @media (min-width: 1366px) and (max-width: 1500px) {
    bottom: 50px;
  }

  @media (min-width: 1010px) and (max-width: 1360px) {
    bottom: 36px;
  }

  @media (min-width: 881px) and (max-width: 1000px) {
    bottom: 30px;
  }

  @media (min-width: 769px) and (max-width: 880px) {
    bottom: 20px;
  }

  @media (max-width: 768px) {
    bottom: 30px;
  }

  @media (max-width: 490px) {
    bottom: 20px;
  }

  @media (max-width: 480px) {
    bottom: 10px;
  }
`;

export const FooterDripping = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 250px;
  background-image: url(${drippingImage});
  background-size: 700px;
  background-position: center top;
  background-repeat: repeat-x;
  background-color: transparent;
  background-blend-mode: normal;
`;

export const FooterWrap = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;
export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: 2;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;
export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;
export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;
export const FooterLinkTitle = styled.h1`
  /* font-size: 14px;
  margin-bottom: 16px;
  color: #000;
  font-family: "Permanent Marker", cursive; */

  color: rgb(211, 76, 73);

  font-family: "Permanent Marker", cursive;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-size: 1.3rem;
  margin-bottom: 16px;
`;
export const FooterLink = styled(Link)`
  color: rgb(78 41 41);
  font-family: "McLaren", sans-serif;

  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;

  /* border: 2px solid rgba(224, 30, 55, 1); */
  border-radius: 0px;

  background: #ffc09c;
  padding: 10px;

  &:hover {
    color: #01bf71;
    transition: 0.3s ease-out;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled(Link)`
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
  color: rgb(223 42 61);
`;

export const WebsiteRights = styled.small`
  color: rgb(223 42 61);

  margin-bottom: 16px;
  padding-left: 80px;

  @media screen and (max-width: 820px) {
    padding-left: 0;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;

export const SocialIconLink = styled.a`
  color: rgb(223 42 61);

  font-size: 24px;
`;
