import React from "react";
import styled from "styled-components";
import ImageArrow from "../../images/arrow.png";

const Image = styled.img`
  position: absolute;
  top: 23%;
  left: 50%;

  width: 210px;
  height: auto;

  transform: translate(-50%, -50%);
  z-index: 2;
`;

const ImgArrow = () => {
  return <Image src={ImageArrow} alt="Ice Cream" />;
};

export default ImgArrow;
