import React from "react";

import { ServicesContainer } from "./styles";
import SideComponent from "../StandarContainer";
import ImageComponent from "../../images/promotional/ice-cream.png";

const Menu = () => {
  return (
    <ServicesContainer id="services">
      {/* <ServicesH1> Our Flavours </ServicesH1> */}
      <SideComponent>
        <img src={ImageComponent} alt="Ice Cream" />
      </SideComponent>

      <SideComponent>
        {/* <img src={ImageComponentTwo} alt="Ice Cream" /> */}
        <span
          style={{
            fontFamily: "Rubik Dirt, system-ui",
            fontStyle: "normal",
            fontSize: "100px",
            textAlign: "left",
            lineHeight: 0.8,
            padding: 20,
          }}
        >
          Happiness in the form of ice cream.
        </span>
        <br />
        <br />

        <h1
          style={{
            fontFamily: "McLaren, sans-serif",
            fontWeight: 400,
            fontStyle: "normal",
            fontSize: "60px",
            textAlign: "left",
            lineHeight: 1,
            padding: 20,
          }}
        >
          We have flavors that are perfect for every taste.
        </h1>
      </SideComponent>
    </ServicesContainer>
  );
};

export default Menu;
