import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";

import styled from "styled-components";

import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinkItems,
  FooterLinksWrapper,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcons,
  WebsiteRights,
  SocialIconLink,
  FooterDripping,
  StyledAnimationContainer,
} from "./styles";
import { animateScroll as scroll } from "react-scroll";

// import logoFooter from "../../images/logo-footer.png";

import { useLottie } from "lottie-react";

import aboutImg from "../../images/_menu/about-us-7.png";
import contactImg from "../../images/_menu/contact-4.png";
import socialImg from "../../images/_menu/social-media-4.png";
import bike from "./Animation/bike-2.json";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const options = {
    animationData: bike,
    loop: true,
  };

  const { View } = useLottie(options);

  return (
    <FooterContainer>
      <StyledAnimationContainer>{View}</StyledAnimationContainer>
      <FooterDripping />
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>
                {" "}
                {/* <img src={aboutImg} alt="Logo" height="36" />{" "} */}
                <img src={aboutImg} alt="Logo" height="32" />{" "}
              </FooterLinkTitle>
              <FooterLink to="/"> Join the Team </FooterLink>
              <FooterLink to="/"> Event Q & A </FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>
                {" "}
                <img src={contactImg} alt="Logo" height="32" />{" "}
              </FooterLinkTitle>
              <FooterLink to="/"> Contact </FooterLink>
              <FooterLink to="/"> Blog </FooterLink>
              <FooterLink to="/"> Login </FooterLink>
              {/* <FooterLink to="/"> Sponsorships </FooterLink> */}
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              {/* <FooterLinkTitle> Videos </FooterLinkTitle>
              <FooterLink to="/"> Submit Video </FooterLink>
              <FooterLink to="/"> Ambassadors </FooterLink>
              <FooterLink to="/"> Agency </FooterLink>
              <FooterLink to="/"> Influencers </FooterLink> */}
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>
                {" "}
                <img src={socialImg} alt="Logo" height="30" />{" "}
              </FooterLinkTitle>
              <FooterLink to="/"> Instagram </FooterLink>
              <FooterLink to="/"> Facebook </FooterLink>
              {/* <FooterLink to="/"> Youtube </FooterLink>
              <FooterLink to="/"> Twitter </FooterLink> */}
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              Sweet Wheels
              {/* <img src={logoFooter} alt="Logo" height="50" />{" "} */}
            </SocialLogo>
            <WebsiteRights>
              {" "}
              Sweet Wheels © {new Date().getFullYear()} All rights reserved.{" "}
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink href="/" target="_blank" aria-label="Facebook">
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Instagram">
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Youtube">
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Twitter">
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Linkedin">
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
