import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import Logo from "../../images/SW_Red_Logo_1.png";
import DrizzleTwo from "../../images/drizzle-2.png";
// import Logo from "../../images/SW_Green_Logo_2.png";

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  DrizzleContent,
} from "./styles";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    let abortController = new AbortController();
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }

    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav}>
          <DrizzleContent>
            <img
              src={DrizzleTwo}
              alt="logo"
              style={{ width: "100px", height: "auto" }}
            />
          </DrizzleContent>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              {" "}
              <img
                src={Logo}
                alt="logo"
                style={{ width: "300px", height: "auto" }}
              />
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  // to="discover"
                  smooth={true}
                  duration={500}
                  // spy={true}
                  activeClass="active"
                  exact="true"
                >
                  {" "}
                  Menu{" "}
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  // to="services"
                  smooth={true}
                  duration={500}
                  // spy={true}
                  activeClass="active"
                  exact="true"
                >
                  {" "}
                  Events{" "}
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  // to="signup"
                  smooth={true}
                  duration={500}
                  // spy={true}
                  activeClass="active"
                  exact="true"
                >
                  {" "}
                  Contact{" "}
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  // to="about"
                  smooth={true}
                  duration={500}
                  // spy={true}
                  activeClass="active"
                  exact="true"
                >
                  {" "}
                  About{" "}
                </NavLinks>
              </NavItem>
            </NavMenu>
            <NavBtn>
              <NavBtnLink to="/signin"> Sign In</NavBtnLink>
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
