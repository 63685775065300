import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import textureNav from "../../images/texture-nav.png"; // Ajuste o caminho conforme necessário

export const Nav = styled.nav`
  background-image: url(${textureNav});
  background-blend-mode: multiply;
  background-color: ${({ scrollNav }) => (scrollNav ? "#ffb03c" : "#d7d347")};
  background-size: cover;
  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: 0.8s all ease;
  border-bottom: 3px solid rgb(243 149 64);
  box-shadow: rgb(0 0 0 / 38%) 2px 0px 9px 6px;
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
`;

export const DrizzleContent = styled.div`
  position: relative;
  top: 180px;
  left: -10px;
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 3px 14px 18px);
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
  text-shadow: 1px 1px #801f06, 0px 2px #287761, 2px 3px 2px #fffd94;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinks = styled(LinkS)`
  color: #d34c49;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  /* font-family: "Nerko One", cursive; */
  font-family: "Permanent Marker", cursive;
  /* font-family: "Sriracha", cursive; */
  /* font-family: "Rock Salt", cursive; */
  /* font-family: "Sedgwick Ave Display", cursive; */
  /* font-family: "Delius", cursive; */
  /* font-family: "Borel", cursive; */
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-size: 1.3rem;

  /*  &: is used to insert styling for pseudo class selectors like
        :active, :hover, :focus in one big css block */
  &:hover,
  &.active {
    transition: color 0.4s ease-in-out, border-bottom-color 0.4s ease-in-out;
  }

  &:hover {
    color: rgb(255 84 84);
  }

  &.active {
    border-bottom: 3px solid rgb(255 84 84);
    color: rgb(255 84 84);
    position: relative;
    margin-bottom: 0px;
  }
`;

// NavBtn for browser: it disappears when max-width: 768px
export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #f39540;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;
