import styled from "styled-components";

export const SideComponent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh);

  position: relative;
  color: #17534c;
  flex-direction: column;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 0px;
  }

  @media (max-width: 768px) {
    img {
      max-width: 100%;
      display: inline-table;
    }
  }
`;
