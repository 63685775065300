import styled from "styled-components";

import oldPaperImage from "./img/old_paper.png";

export const ApproachContainer = styled.div`
  background-size: 150px 150px;
  background-image: url(${oldPaperImage});
  background-repeat: repeat;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 40vh;
  width: 100vw;

  position: relative;
  z-index: 1;
  overflow: hidden;

  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    display: inline-table;
  }
`;

export const ServicesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const ServicesCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`;

export const ServicesH1 = styled.h1`
  font-size: 1.6rem;
  color: #000;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='14' height='10' viewBox='0 0 14 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 3.634l-7 6V7l7-6 7 6v2.634l-7-6zm14 0l-7 6V7l7-6 7 6v2.634l-7-6z' fill='rgba(225, 149, 12, 0.9)' class='zig'/%3E%3C/svg%3E");
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    background-position: bottom;
    background-size: 14px 10px;
    background-repeat: repeat-x;
    padding-bottom: 14px;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const ServicesH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
  padding: 17rem;
`;
