import React, { useState } from "react";
import styled from "styled-components";

const SubscribeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
`;

const SubscribeInput = styled.input`
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const SubscribeButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const SubscribeComponent = () => {
  const [email, setEmail] = useState("");

  const handleSubscribe = () => {
    // Lógica para lidar com a inscrição (por exemplo, enviar o e-mail para um servidor)
    alert(`Subscribed with email: ${email}`);
  };

  return (
    <SubscribeContainer>
      <h2>Subscribe to our newsletter</h2>
      <SubscribeInput
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <SubscribeButton onClick={handleSubscribe}>Subscribe</SubscribeButton>
    </SubscribeContainer>
  );
};

export default SubscribeComponent;
