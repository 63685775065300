import styled from "styled-components";

export const SideComponent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  margin-top: 80px;
  position: relative;

  /* min-height: 900px; */
  color: #fff;
  /* border: 1px solid #000000; */
  flex-direction: column;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 0px;
  }

  @media (max-width: 768px) {
    img {
      max-width: 100%;
      display: inline-table;
      /* margin-top: 0px; */
    }
  }
`;
