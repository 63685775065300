import React, { useState } from "react";
import HeroSection from "../components/HeroSection";
import ImgArrow from "../components/ImageArrow";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Menu from "../components/Menu/index";
import Services from "../components/Services/index";
import Icecream from "../components/Icecream";
import Approach from "../components/Approach";
import Twice from "../components/TwiceComponent";

import Footer from "../components/Footer";
import styled from "styled-components";

const HomeContainer = styled.div`
  position: relative;
`;

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    let abortController = new AbortController();
    setIsOpen(!isOpen);
    return () => {
      abortController.abort();
    };
  };

  return (
    <HomeContainer>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <ImgArrow />
      <HeroSection />
      <Menu />
      <Icecream />
      <Approach />
      <Twice />
      <Services />
      <Footer />
    </HomeContainer>
  );
};

export default Home;
