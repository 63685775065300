import React from "react";
import { SideComponent } from "./styles";

const HeroContent = ({ children }) => {
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <SideComponent>{children}</SideComponent>
    </div>
  );
};

export default HeroContent;
